<template>
  <ItemWrapper>
    <AppCard
      :prev-route="prevRoute"
      :loading="pending.getInvoice"
      :title="`${$t('Invoice')} ${!_.isNull(invoice.invoice_nr) ? invoice.invoice_nr : ''}`"
    >
      <template v-if="!pending.getInvoice">
        <v-row justify-start wrap>
          <v-col cols="12" sm="4">
            <v-card flat tile>
              <v-card-title class="py-2">{{ $t('Provider') }}</v-card-title>
              <v-card-text v-if="tenant_handle && tenant_handle.id">
                <address>
                  <template v-if="tenant_handle.organisation">
                    <span class="font-weight-bold">{{ tenant_handle.organisation }}</span>
                    <br />
                  </template>
                  <span v-if="tenant_handle.sex == 'MALE'">Herr</span>
                  <span v-if="tenant_handle.sex == 'FEMALE'">Frau</span>
                  {{ tenant_handle.firstname }} {{ tenant_handle.lastname }}
                  <br />
                  {{ tenant_handle.street }} {{ tenant_handle.number }}
                  <br />
                  {{ tenant_handle.zip }} {{ tenant_handle.city }}
                  <br />
                  {{ tenant_handle.country }}
                  <br />
                  <b>Phone:</b>
                  {{ tenant_handle.phone }}
                  <br />
                  <b>Fax:</b>
                  {{ tenant_handle.fax }}
                  <br />
                  {{ tenant_handle.email }}
                  <br />
                </address>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card flat tile>
              <v-card-title class="py-2">{{ $t('Client') }}</v-card-title>
              <v-card-text v-if="client_handle && client_handle.id">
                <address>
                  <template v-if="client_handle.organisation">
                    <span class="font-weight-bold">{{ client_handle.organisation }}</span>
                    <br />
                  </template>
                  <span v-if="client_handle.sex == 'MALE'">Herr</span>
                  <span v-if="client_handle.sex == 'FEMALE'">Frau</span>
                  {{ client_handle.firstname }} {{ client_handle.lastname }}
                  <br />
                  {{ client_handle.street }} {{ client_handle.number }}
                  <br />
                  {{ client_handle.zip }} {{ client_handle.city }}
                  <br />
                  {{ client_handle.country }}
                  <br />
                  <b>Phone:</b>
                  {{ client_handle.phone }}
                  <br />
                  <b>Fax:</b>
                  {{ client_handle.fax }}
                  <br />
                  {{ client_handle.email }}
                  <br />
                </address>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card flat tile>
              <v-card-title class="py-2">{{ $t('Infos') }}</v-card-title>
              <v-card-text>
                <p v-if="invoice.invoice_nr">
                  <b>Invoice:</b>
                  <br />
                  <span>{{ invoice.invoice_nr }}</span>
                  <br />
                </p>
                <p>
                  <b>Invoice Date:</b>
                  <br />
                  <span>{{ invoice.billingdate | moment('DD.MM.YYYY') }}</span>
                  <br />
                </p>
                <p>
                  <b>Zahlung fällig:</b>
                  <br />
                  <span>{{ invoice.billingdate | moment('add', `${invoice.paymenttime} days`, 'DD.MM.YYYY') }}</span>
                  <br />
                </p>
                <p v-if="invoice.shipping_type && invoice.shipping_type !== 'hide'">
                  <b>{{ $t(invoice.shipping_type) || 'shipping_type' }}:</b>
                  <br />
                  <span>{{ invoice.shippingdate | moment('DD.MM.YYYY') }}</span>
                  <template v-if="isShippingPeriod">
                    bis
                    <span>{{ invoice.shippingenddate | moment('DD.MM.YYYY') }}</span>
                  </template>
                  <br />
                </p>
                <p>
                  <b>Account:</b>
                  <br />
                  <span v-if="invoice.client">{{ invoice.client.customer }}</span>
                  <br />
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify-start>
          <v-col cols="12">
            <v-card flat tile>
              <v-card-title class="py-2">{{ $t('Products') }}</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="invoice.invoice_products"
                  :hide-default-footer="invoice.invoice_products.length <= 5"
                >
                  <template #[`item.netto`]="{ item }">
                    {{ $n((item.amount * item.quantity * (100 - item.discount)) / 100, 'currency', 'de-DE') }}
                  </template>

                  <template #[`item.discount`]="{ item }">
                    {{ item.discount }} {{ item.discount_type === 'percent' ? '%' : '€' }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify-start wrap>
          <v-col cols="12" sm="6">
            <!-- <p v-if="invoice.payment_method">Payment Methods:</p> -->
            <!-- <img src="../../dist/img/credit/visa.png" alt="Visa">
                  <img src="../../dist/img/credit/mastercard.png" alt="Mastercard">
                  <img src="../../dist/img/credit/american-express.png" alt="American Express">
                  <img src="../../dist/img/credit/paypal2.png" alt="Paypal"> -->

            <v-card flat tile>
              <v-card-title class="py-2">{{ $t('Rechnungstext') }}</v-card-title>
              <v-card-text>
                <p>
                  {{ invoice.extratext }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Subtotal:</td>
                    <td>{{ parseFloat(totals.netto.toFixed(2)) }} €</td>
                  </tr>
                  <tr v-for="(tax, key) in totals.tax" :key="key">
                    <td class="font-weight-bold">MwSt. {{ key }}%</td>
                    <td>{{ parseFloat(tax.toFixed(2)) }} €</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Total:</td>
                    <td>{{ parseFloat(totals.total.toFixed(2)) }} €</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col> -->
        </v-row>

        <v-row justify-start wrap>
          <v-col cols="12" sm="6">
            <v-btn :loading="pending.getInvoicePdf" color="info" @click="downloadPdf">
              <v-icon>mdi-download</v-icon>
              Download PDF
            </v-btn>
            <!-- <button type="button" class="btn btn-success pull-right"><i class="fa fa-credit-card"></i> Submit Payment
                  </button> -->
          </v-col>

          <v-col cols="12" sm="6" class="text-sm-right">
            <v-btn color="info" :loading="pending.getInvoicePdf" @click="showPdf">
              <v-icon>mdi-file-pdf-outline</v-icon>
              Zeige PDF
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <AppDialog :is-open="visiblePdf" max-width="800px" @onCloseDialog="visiblePdf = false">
        <template slot="title">View PDF</template>

        <v-responsive :aspect-ratio="210 / 297">
          <object
            :data="`data:application/pdf;base64,${invoicePdf}`"
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </v-responsive>
      </AppDialog>
    </AppCard>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppDialog from '@/components/UI/AppDialog'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewInvoice',
  components: {
    AppCard,
    AppDialog,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/Invoices')
    const visiblePdf = ref(false)
    const headers = [
      { text: '#', value: 'artNr' },
      { text: 'Product', value: 'title' },
      { text: 'Description', value: 'description' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Amount', value: 'amount' },
      { text: 'Discount', value: 'discount' },
      { text: 'Tax', value: 'tax' },
      { text: 'Netto', value: 'netto', sortable: false },
    ]

    // store
    const pending = computed(_ => $store.state.invoice.pending)
    const invoice = computed(_ => $store.state.invoice.invoice)
    const invoicePdf = computed(_ => $store.state.invoice.invoicePdf)
    const getInvoice = _ => $store.dispatch('invoice/getInvoice')
    const getInvoicePdf = invoiceId => $store.dispatch('invoice/getInvoicePdf', invoiceId)
    const clearInvoice = _ => $store.dispatch('invoice/clearInvoice')

    getInvoice()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    const tenant_handle = computed(_ => invoice.value.tenant?.billing_handle)
    const client_handle = computed(_ => invoice.value.client?.billing_handle)

    const showPdf = _ => {
      getInvoicePdf(invoice.value.id).then(_ => (visiblePdf.value = true))
    }
    const downloadPdf = _ => {
      getInvoicePdf(invoice.value.id).then(_ => {
        const link = document.createElement('a')
        link.download = `${invoice.value.invoice_nr || 'rechnung_entwurf'}.pdf`
        link.href = 'data:application/octet-stream;base64,' + invoicePdf.value
        link.click()
      })
    }

    const isShippingPeriod = computed(
      _ =>
        invoice.value.shipping_type &&
        invoice.value.shipping_type !== 'hide' &&
        invoice.value.shipping_type.includes('period'),
    )

    onUnmounted(_ => {
      clearInvoice()
    })

    return {
      prevRoute,
      visiblePdf,
      headers,
      pending,
      invoice,
      tenant_handle,
      client_handle,
      isShippingPeriod,
      showPdf,
      downloadPdf,
      invoicePdf,
    }
  },
}) //
</script>
